// Example static data. In a real app, fetch this from an API.
export const artists = [
    {
        id: 'artist-1',
        name: 'JP Solis',
        socials: {
            instagram: 'https://instagram.com/jpsolis.nyc',
            bandcamp: 'https://jpsolis.bandcamp.com',
            soundcloud: 'https://soundcloud.com/jpsolis808',
        },
        selectedDate: '2024-12-01',
        bio: `JP Solis is renowned for spinning marathon sets that fuse “New York Swing” with “Chicago Shuffle,” honed by working at iconic record shops and DJing downtown clubs six nights a week. He rose to prominence in Manhattan’s last great superclub era, studying mash-up maestros like Mark Ronson and DJ AM, and refining his sound engineering passion alongside Steve Dash’s Phazon systems.

        Beyond the booth, JP co-founded Dubspot, a pioneering DJ/production school, launched the NOISE212 livestream series (hosting early sets by Baauer, MikeQ, and more), and built a wide-ranging career in mixing and mastering. His credits span film, television, and major artists—testament to an unwavering obsession with quality sound.`,
        youtubeLinks: [
            'https://www.youtube.com/embed/loaaPXFfhdI',
            'https://www.youtube.com/embed/4YqI5LXLIEg'
        ],
        thumbnailUrl: 'https://example.com/thumbnail.jpg'
    },
    {
        id: 'artist-2',
        name: 'WITNESS PROTECTION',
        socials: {
            instagram: 'https://instagram.com/witnessprotection.us',
            spotify: "https://open.spotify.com/artist/2TPWIwSRV1U8zloCokMmUY?si=J7B1EgufSi6z9sD8P5SLsQ",
            apple: "https://music.apple.com/us/artist/witness-protection/1774028993",
            tiktok: "https://www.tiktok.com/@witnessprotection"
        },
        selectedDate: '2024-12-07',
        bio: `comprised of Jordan (originally from California) and Will (from Alabama)—draw on their distinct backgrounds and musical influences to craft a fresh, unforgettable sound that lights up dancefloors. Taking cues from renowned house labels like Music On, Mindshake, PIV, and Black Seven Music, they fuse groovy rhythms with high-energy vibes, moving crowds and connecting audiences wherever they play.`,
        youtubeLinks: [
            'https://www.youtube.com/embed/IVrmp55jHUo',
            'https://www.youtube.com/embed/sUYWGFp3bk0',
        ],
        thumbnailUrl: 'https://example.com/thumbnail.jpg'
    },
    {
        id: 'artist-3',
        name: 'Kcik',
        socials: {
            instagram: 'https://instagram.com/kcik_msic', // Replace with actual handle if different
            bandcamp: 'https://kcik.bandcamp.com/',
            spotify: 'https://open.spotify.com/artist/701mrkwQWqwpcRjfCNwZ1u', // Replace 'kcik' with actual artist ID if available
            apple: 'https://music.apple.com/us/artist/kcik/1694481821',
            soundcloud: 'https://soundcloud.com/kcik', // Replace with actual SoundCloud URL if different
            youtube: 'https://www.youtube.com/channel/UCuPfhUQIiR7h5KobSrb-mmw?si=J7B1EgufSi6z9sD8P5SLsQ' // Replace with actual YouTube channel if different
        },
        selectedDate: '2024-12-15',
        bio: `Kcik is a rising musical artist known for their deep house, dreamy-like soundscapes. Blending lush melodies with infectious rhythms, Kcik creates an immersive listening experience that transports audiences to ethereal dancefloors. Their music seamlessly fuses atmospheric textures with groovy beats, making every performance a captivating journey.`,
        youtubeLinks: [
            'https://www.youtube.com/embed/4adX8Ul-5T0',
            'https://www.youtube.com/embed/VArr0F8tGLs',

        ],
        thumbnailUrl: 'https://example.com/kcik-thumbnail.jpg' // Replace with actual thumbnail URL
    }
];
