import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.scss";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="Nav">
      <div id="navContainer">
        <img src="/assets/sblogo.png" alt="Logo" id="mainLogo" />
        <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div id="navLinks" className={isMenuOpen ? "open" : ""}>
          <Link to="/" onClick={toggleMenu}>Home</Link>
          {/* <Link to="/about" onClick={toggleMenu}>About</Link> */}
          <Link to="/artistspotlight" onClick={toggleMenu}>Artist Spotlight</Link>
          <Link to="/shop" onClick={toggleMenu}>Shop</Link>
          {/* <Link to="/radio" onClick={toggleMenu}>Radio</Link> */}
          {/* <Link to="/playlists" onClick={toggleMenu}>Playlists</Link> */}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
