import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { artists } from '../data/artists';
import styles from '../ArtistSpotlight.module.scss';

const platformNames = {
    instagram: "Instagram",
    twitter: "Twitter",
    bandcamp: "Bandcamp",
    soundcloud: "SoundCloud",
    spotify: "Spotify",
    apple: "Apple Music",
    tiktok: "TikTok",
    youtube: "YouTube"
    // Add more mappings as needed
};

const ArtistDetail = () => {
    const { artistId } = useParams();
    const artist = artists.find(a => a.id === artistId);

    if (!artist) {
        return <div className={styles.notFound}>Artist not found</div>;
    }

    return (
        <div className={styles.detailContainer}>
            <Link to="/artistspotlight" className={styles.backLink}>← Back to Artists</Link>
            <ArtistDetailInfo artist={artist} />
        </div>
    );
};

export default ArtistDetail;

export const ArtistDetailInfo = ({ artist }) => {
    return (
        <div className={styles.artistDetail}>
            <h1 className={styles.detailName}>{artist.name}</h1>
            <p className={styles.detailBio}>{artist.bio}</p>
            <div className={styles.detailSocials}>
                {Object.entries(artist.socials).map(([platform, url]) => {
                    if (!url) return null; // skip if no URL
                    const displayName = platformNames[platform] || platform;
                    return (
                        <a key={platform} href={url} target="_blank" rel="noopener noreferrer">
                            {displayName}
                        </a>
                    );
                })}
            </div>
            <h2 className={styles.videoSectionTitle}>Featured Sets / Songs</h2>
            <div className={styles.videoContainer}>
                {artist.youtubeLinks.map((link, index) => (
                    <div key={index} className={styles.videoWrapper}>
                        <iframe
                            width="560"
                            height="315"
                            src={link}
                            title={`YouTube video player ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                ))}
            </div>
        </div>
    );
};
