import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate, useLocation } from "react-router-dom";
import "./Shop.scss";

const Shop = ({ products, checkout, client, isPDP }) => {

  const [categories, setCategories] = useState(["All"]); // Start with "All"

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProductDetailVisible, setIsProductDetailVisible] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [activeFilter, setActiveFilter] = useState("All");

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProduct, setModalProduct] = useState(null);
  const [selectedVariantId, setSelectedVariantId] = useState(null);


  useEffect(() => {
    if (products && products.length > 0) {
      const uniqueTypes = Array.from(
        new Set(products.map((p) => p.productType).filter(Boolean))
      );
      // Combine "All" with whatever unique productTypes exist
      setCategories(["All", ...uniqueTypes]);
      // Reset filteredProducts to show all by default
      setFilteredProducts(products);
    }
  }, [products]);

  // Filtering logic
  const handleFilter = (category) => {
    setActiveFilter(category);

    if (category === "All") {
      setFilteredProducts(products);
    } else {
      // Filter by exact match of productType
      const newFiltered = products.filter((p) => p.productType === category);
      setFilteredProducts(newFiltered);
    }
  };

  // Open the Quick Buy Modal
  const handleQuickBuy = (e, product) => {
    e.preventDefault();
    // Set the product we want to buy
    setModalProduct(product);
    // Default variant selection to the first variant
    const defaultVariantId = product?.variants?.[0]?.id || null;
    setSelectedVariantId(defaultVariantId);
    setIsModalOpen(true);
  };

  // Close the Quick Buy Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalProduct(null);
  };

  // Add to Cart from the Quick Buy Modal
  const handleAddToCartModal = async () => {
    try {
      if (!checkout || !client || !selectedVariantId) return;

      const lineItems = [
        {
          variantId: selectedVariantId,
          quantity: 1,
        },
      ];

      const updatedCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );
      // Redirect or open new tab
      window.open(updatedCheckout.webUrl, "_blank");

      // Optionally close the modal after adding to cart
      closeModal();
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setIsTransitioning(true);
    // Delay setting visibility to allow for the blur to start first
    setTimeout(() => {
      setIsProductDetailVisible(true);
    }, 50);
  };

  const handleCloseProductDetail = () => {
    setIsProductDetailVisible(false);
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedProduct(null);
      setIsTransitioning(false);
    }, 400); // Match this with the transition duration in CSS
  };

  useEffect(() => {
    // If on PDP route, lock scroll; otherwise restore it
    document.body.style.overflow = isPDP ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isPDP]);

  return (
    <div className="shop">
      <header className="shop-header">
        <h1>Spinnin' Beans Shop</h1>

        {/* Dynamic Navigation Menu */}
        <nav>
          <ul>
            {categories.map((cat) => (
              <li key={cat}>
                <a
                  href={`#${cat}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilter(cat);
                  }}
                  className={activeFilter === cat ? "active" : ""}
                >
                  {cat}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <main className="shop-main">
        <Routes>
          <Route
            path="/"
            element={
              <section className="product-grid">
                {products.map((product) => {
                  const productImage = product.images?.[0]?.src || 'fallback.jpg';
                  const productPrice = product.variants?.[0]?.price?.amount || '0.00';

                  // Use product.handle for the URL
                  return (
                    <Link
                      key={product.id}
                      to={`product/${product.handle}`}
                      className="product-card"
                    >
                      <img src={productImage} alt={product.title} />
                      <h3>{product.title}</h3>
                      <p>${productPrice}</p>
                      <button
                        onClick={(e) => handleQuickBuy(e, product)}
                      >
                        Quick Buy
                      </button>
                    </Link>
                  );
                })}
              </section>
            }
          />
          {/* Use :handle instead of :id here */}
          <Route path="product/:handle" element={<ProductDetail products={products} checkout={checkout} client={client} />} />
        </Routes>
      </main>
      {/* Quick Buy Modal */}
      {isModalOpen && modalProduct && (
        <div className="quick-buy-modal">
          <div className="modal-content">
            <button className="modal-close" onClick={closeModal}>×</button>

            <h2>{modalProduct.title}</h2>
            <p>${(modalProduct.variants?.[0]?.price?.amount || "0.00")}</p>
            <div className="modal-images">
              {modalProduct.images?.map((img, idx) => (
                <img key={idx} src={img.src} alt={`${modalProduct.title} - View ${idx + 1}`} />
              ))}
            </div>

            {/* Variant Selection */}
            {modalProduct.variants && modalProduct.variants.length > 1 && (
              <div className="variant-selector">
                <label htmlFor="variant-select">Select Size:</label>
                <select
                  id="variant-select"
                  value={selectedVariantId || ""}
                  onChange={(e) => setSelectedVariantId(e.target.value)}
                >
                  {modalProduct.variants.map((variant) => (
                    <option key={variant.id} value={variant.id}>
                      {variant.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Add to Cart from Modal */}
            <button className="add-to-cart" onClick={handleAddToCartModal}>
              Buy Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ProductDetail = ({ products, checkout, client }) => {
  const { handle } = useParams();
  const navigate = useNavigate();

  // Refs for the two panels
  const imagesRef = useRef(null);
  const contentRef = useRef(null);

  // Touch tracking ref
  const lastTouchYRef = useRef(null);

  // Find the product by handle
  const product = products.find((p) => p.handle === handle);
  const [selectedVariantId, setSelectedVariantId] = useState(
    product?.variants?.[0]?.id || null
  );

  useEffect(() => {
    // ====================
    //   WHEEL SCROLL
    // ====================
    function handleWheel(e) {
      e.preventDefault(); // We will manually handle scroll

      const imagesEl = imagesRef.current;
      const infoEl = contentRef.current;
      if (!imagesEl || !infoEl) return;

      const deltaY = e.deltaY;
      const isScrollingDown = deltaY > 0;

      // Calculate how far each panel can scroll
      const imagesScrollTop = imagesEl.scrollTop;
      const imagesMaxScroll = imagesEl.scrollHeight - imagesEl.clientHeight;

      const infoScrollTop = infoEl.scrollTop;
      const infoMaxScroll = infoEl.scrollHeight - infoEl.clientHeight;

      // Check if the images panel is actually scrollable
      const canScrollImages = imagesMaxScroll > 0;
      // Check if the info panel is actually scrollable
      const canScrollInfo = infoMaxScroll > 0;

      if (isScrollingDown) {
        // SCROLLING DOWN
        if (canScrollImages && imagesScrollTop < imagesMaxScroll) {
          // Images not yet at bottom => keep scrolling images
          imagesEl.scrollTop = Math.min(
            imagesScrollTop + deltaY,
            imagesMaxScroll
          );
        } else {
          // Otherwise, scroll the info panel
          if (canScrollInfo) {
            infoEl.scrollTop = Math.min(
              infoScrollTop + deltaY,
              infoMaxScroll
            );
          }
        }
      } else {
        // SCROLLING UP (deltaY < 0)
        if (canScrollInfo && infoScrollTop > 0) {
          // Info not at top => scroll info upward
          infoEl.scrollTop = Math.max(infoScrollTop + deltaY, 0);
        } else {
          // Otherwise, scroll images upward
          if (canScrollImages) {
            imagesEl.scrollTop = Math.max(imagesScrollTop + deltaY, 0);
          }
        }
      }
    }

    // ====================
    //  TOUCH SCROLL
    // ====================
    function handleTouchStart(e) {
      if (e.touches && e.touches.length > 0) {
        lastTouchYRef.current = e.touches[0].pageY;
      }
    }

    function handleTouchMove(e) {
      e.preventDefault(); // We'll do manual scroll

      const imagesEl = imagesRef.current;
      const infoEl = contentRef.current;
      if (!imagesEl || !infoEl) return;

      const currentY = e.touches[0].pageY;
      const prevY = lastTouchYRef.current ?? currentY;
      const deltaY = prevY - currentY; // Positive if swiping up, negative if swiping down
      lastTouchYRef.current = currentY;

      // Reuse the same logic as wheel
      handleWheel({ preventDefault: () => { }, deltaY });
    }

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchstart", handleTouchStart, { passive: false });
    window.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  if (!product) return <div>Product not found</div>;

  const productImages = product.images || [];
  const productPrice = product.variants?.[0]?.price?.amount || "0.00";

  // "Buy Now" logic
  const handleAddToCart = async () => {
    try {
      if (!checkout || !client || !selectedVariantId) return;
      const lineItems = [{ variantId: selectedVariantId, quantity: 1 }];
      const updatedCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );
      window.open(updatedCheckout.webUrl, "_blank");
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  return (
    <div className="product-detail">
      <button className="close-button" onClick={() => navigate("/shop")}>
        ×
      </button>
      <div className="pdp-container">
        {/* Left side: images */}
        <div className="pdp-images" ref={imagesRef}>
          {productImages.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={`${product.title} - View ${index + 1}`}
            />
          ))}
        </div>
        {/* Right side: info */}
        <div className="product-info" ref={contentRef}>
          <h2>{product.title}</h2>
          <p className="price">${parseFloat(productPrice).toFixed(2)}</p>
          <p className="category">
            Category: {product.productType || "N/A"}
          </p>
          {product.variants && product.variants.length > 1 && (
            <div className="variant-selector">
              <label htmlFor="variant-select">Select Size:</label>
              <select
                id="variant-select"
                value={selectedVariantId}
                onChange={(e) => setSelectedVariantId(e.target.value)}
              >
                {product.variants.map((variant) => (
                  <option key={variant.id} value={variant.id}>
                    {variant.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          <button className="add-to-cart" onClick={handleAddToCart}>
            Buy Now
          </button>

          <div className="product-details">
            <h3>Product Details</h3>
            <div
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
          </div>

          <footer className="product-footer">
            <p>© 2024 Spinnin' Beans. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Shop;